@import '@mssgme/scss-basics/vars';

.root {
    width: 100%;
    overflow: hidden;
    position: relative;
    padding-top: 41%;
}

.rootInner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    > img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
