@import '@mssgme/scss-basics/vars';

.root {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.rootInner {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    scroll-behavior: smooth;
}

@media all and (min-width: 601px) {
    .rootInner::-webkit-scrollbar {
        width: 4px;
    }

    .rootInner::-webkit-scrollbar-track {
        background: #55555500;
    }

    .rootInner::-webkit-scrollbar-thumb {
        background: #55555580;
        border-radius: 2px;
    }
}

.content {
    width: 600px;
    max-width: 100%;
    min-height: 100%;
    font-weight: normal;
    font-size: 16px;
    line-height: 1.5;
    color: #333;
    position: relative;
    margin: auto;
    display: flex;
    flex-direction: column;
}

.overlayContainer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    pointer-events: none;
}

.overlayContainerContent {
    width: 600px;
    height: 100%;
    max-width: 100%;
    position: relative;
    margin: auto;
}
