.root {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;

    > img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
